import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { DefaultButton, registerIcons, IIconProps, initializeIcons } from "@fluentui/react";

import styles from "./button.module.css";
import msIcon from "../../assets/microsoft_logo.svg";

registerIcons({
    icons: {
        "microsoft-svg": <img src={msIcon} />
    }
});

const microsoftIcon: IIconProps = { iconName: "microsoft-svg" };

initializeIcons();
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType: any) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    };
    return (
        <DefaultButton className={styles.signInButton} iconProps={microsoftIcon} onClick={() => handleLogin("redirect")}>
            Sign in with Microsoft
        </DefaultButton>
    );
};
