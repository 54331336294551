import React from "react";
import { useMsal } from "@azure/msal-react";
import { DefaultButton } from "@fluentui/react";

import styles from "./button.module.css";

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType: any) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/#/file",
                mainWindowRedirectUri: "/#/file"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/#/file"
            });
        }
    };

    return <DefaultButton onClick={() => handleLogout("redirect")}>Sign out</DefaultButton>;
};
