import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Spinner, ISpinnerStyles, DefaultButton, SpinnerSize, MessageBar, MessageBarType } from "@fluentui/react";
import { getFileList, uploadFileApi, fileReset, FileRequest, FileResponse } from "../../api";
import { GrDocument } from "react-icons/gr";

import styles from "./FileDropzone.module.css";

interface Props {
    // onSend: (question: string) => void;
    // disabled: boolean;
    // placeholder?: string;
    // clearOnSend?: boolean;
}

export const FileDropzone = ({}: // onSend, disabled, placeholder, clearOnSend
Props) => {
    const spinnerStyles = {
        label: {
            color: "black",
            fontSize: "16px"
        }
    };

    const messageBoxStyles = {
        text: {
            fontSize: "16px"
        },
        icon: {
            fontSize: "18px"
        }
    };

    const [fileList, setFileList] = useState<string[]>([]);

    const [uploading, setUploading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState("");
    const [resetButtonEnable, setResetButtonEnable] = useState(true);

    const [showMessageBar, setShowMessageBar] = useState(false);
    const [messageBarType, setMessageBarType] = useState<MessageBarType>(MessageBarType.info);
    const [messageBarText, setMessageBarText] = useState("");

    const determineUploadMessage = (files: File[]) => {
        var message = "";

        if (files && files.length > 0) {
            var firstFile = files[0];

            if (files.length == 1) {
                message = '"' + firstFile.name + '"';
            } else {
                if (files.length == 2) {
                    message = '"' + firstFile.name + '"' + "and 1 more file";
                } else {
                    message = '"' + firstFile.name + '"' + "and " + (files.length - 1) + " more files";
                }
            }
        }

        setUploadMessage(message);
    };

    const makeApiRequest = async (files: File[]) => {
        const fileRequest: FileRequest = {
            files: files
        };

        determineUploadMessage(files);
        setUploading(true);

        setMessageBarType(MessageBarType.warning);
        setMessageBarText("Upload in progress ... this may take a while");
        setShowMessageBar(true);
        resetMessageBar(10);

        const fileResponse = await uploadFileApi(fileRequest);

        console.log("File upload status: " + fileResponse.status);
        if (fileResponse.status == "Success") {
            console.log("Files uploaded:");
            fileResponse.filenames.forEach(file => {
                console.log(file);
            });
            setUploading(false);

            refreshFileList();

            setMessageBarType(MessageBarType.success);
            setMessageBarText("Upload complete - ready to chat");
            setShowMessageBar(true);
            resetMessageBar(5);
        } else {
            console.log(fileResponse.status);

            setMessageBarType(MessageBarType.error);
            setMessageBarText("Upload failed - please try again");
            setShowMessageBar(true);
            resetMessageBar(10);
        }

        setUploading(false);
    };

    const refreshFileList = async () => {
        const filesResponse = await getFileList();
        if (filesResponse.status == "Success") {
            setFileList(filesResponse.filenames);
        }
    };

    const formatFileListName = (name: string) => {
        var maxLength = 35;
        return name.length > maxLength ? name.slice(0, maxLength - 1) + "..." : name;
    };

    const resetDemo = async () => {
        setResetButtonEnable(false);

        setMessageBarType(MessageBarType.warning);
        setMessageBarText("Reset in progress ... this may take a while");
        setShowMessageBar(true);
        resetMessageBar(10);

        var result = await fileReset();

        refreshFileList();

        if (result) {
            setMessageBarType(MessageBarType.success);
            setMessageBarText("Reset complete - ready for new files");
            setShowMessageBar(true);
            resetMessageBar(5);
        } else {
            setMessageBarType(MessageBarType.error);
            setMessageBarText("Reset failed - please try again.");
            setShowMessageBar(true);
            resetMessageBar(10);
        }

        setResetButtonEnable(true);
    };

    const resetMessageBar = (dismissDelayInSeconds: number) => {
        if (dismissDelayInSeconds !== 0) {
            setTimeout(() => {
                setMessageBarType(MessageBarType.info);
                setMessageBarText("");
                setShowMessageBar(false);
            }, dismissDelayInSeconds * 1000);
        }
    };

    const testMessageBar = () => {
        setMessageBarType(MessageBarType.warning);
        setMessageBarText("This is a long message. Let's see if it wraps multiple properly. Please wait.");
        setShowMessageBar(true);
        resetMessageBar(5);
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        // Do something with the files
        // console.log("Files uploaded:")
        // acceptedFiles.forEach((file)=>{
        //     console.log(file.name);
        // })
        if (acceptedFiles) {
            console.log("Processing files...");
            makeApiRequest(acceptedFiles);
        } else {
            console.log("No valid files to process");
        }
    }, []);

    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "application/pdf": []
        },
        onDrop
    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    useEffect(() => {
        refreshFileList();
    }, []);

    return (
        <section className={styles.container}>
            <h2>File Upload</h2>
            <div {...getRootProps({ className: styles.dropzone })}>
                {uploading && (
                    <div className={styles.uploadContent}>
                        <Spinner className={styles.spinnerText} labelPosition="right" label={uploadMessage} size={SpinnerSize.medium} styles={spinnerStyles} />
                    </div>
                )}
                {!uploading && (
                    <div className={styles.uploadContent}>
                        <input {...getInputProps()} disabled={uploading} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        {/* <em>(Only files with name less than 20 characters will be accepted)</em> */}
                    </div>
                )}
            </div>
            <div className={styles.yourFiles}>
                {/* <h2>File Status: {uploadStatus}</h2> */}
                {/* <h4>Accepted files</h4>
                <ul>{acceptedFileItems}</ul>
                <h4>Rejected files</h4>
                <ul>{fileRejectionItems}</ul> */}
                <h4>Your Files</h4>
                <hr />
                <ul className={styles.fileList}>
                    {fileList.map(file => (
                        <li>
                            <GrDocument /> {formatFileListName(file)}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.messageBarContainer}>
                {showMessageBar && (
                    <MessageBar
                        className={styles.messageBar}
                        delayedRender={false}
                        // Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
                        messageBarType={messageBarType}
                        // Or you could set the role manually, IF an alert role is appropriate for the message
                        // role="alert"
                        isMultiline={true}
                        styles={messageBoxStyles}
                    >
                        {messageBarText}
                    </MessageBar>
                )}
            </div>
            <div>
                <DefaultButton text="Reset demo" onClick={resetDemo} disabled={!resetButtonEnable} />
                {/* <DefaultButton text="Test message bar" onClick={testMessageBar} /> */}
            </div>
        </section>
    );
};
